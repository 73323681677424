






















































import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { CommunityDetailViewModel } from '../../viewmodels/community-detail-viewmodel'

@Observer
@Component({
  components: {},
})
export default class ManageCommunityBoard extends Vue {
  createPostController = createPostController
  @Inject({}) vm!: CommunityDetailViewModel
  copyLink() {
    navigator.clipboard.writeText(`https://dev-alpha.netlify.app${this.$route.fullPath}`)
  }
}
